



























































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import DropDown from '@/components/DropDown.vue';
import {VEmojiPicker} from 'v-emoji-picker';
import {MessagesGroupInterface} from '@/types/messagesGroupInterface';
import {vxm} from '@/store';
import {Pagination} from '@/types/Pagination';
import {MessageInterface} from '@/types/messageInterface';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import VueChatScroll from 'vue-chat-scroll';
import moment from 'moment';
import DebounceMixin from '@/mixins/DebounceMixin';
import Loader from '@/components/Loader.vue';
import {FileSizeEnum} from '@/types/enums/FileSizeEnum';
import NumbersMixin from '@/mixins/NumbersMixin';
import linkify from 'vue-linkify';
import DatePickerModal from '@/components/modals/DatePickerModal.vue';
import VidsPlusLinkPicker from '@/components/VidsPlusLinkPicker.vue';
import {VideoInterface} from '@/types/videoInterface';

Vue.use(VueChatScroll);

@Component({
  components: {Loader, DropDown, VEmojiPicker, DatePickerModal, VidsPlusLinkPicker},
  directives: {linkify},
})
export default class ChatMessages extends Mixins(MomentDateMixin, DebounceMixin, NumbersMixin) {
  message = '';
  messages: Pagination<MessageInterface> = {count: 0, items: []};
  searchQuery = '';
  showSearchInput = false;
  showEmoji = false;
  showVidsLink = false;
  params = {
    page: 1,
    take: 20,
    keyword: '',
    start: '',
    end: '',
  };
  chatDates: string[] = [];
  debouncedSearch = this.debounce(this.search, 700);
  datesFilter: {start: string; end: string} = {
    start: moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  };

  @Prop({default: null}) contact!: MessagesGroupInterface | null;

  get isPerformer(): boolean {
    return vxm.user.role === 'performer';
  }

  mounted() {
    this.sockets.subscribe('NEW_MESSAGE', (message: MessageInterface) => {
      if (message.messageGroupId === this.contact?._id) {
        this.messages.items.unshift(message);
        this.messages.count++;
        this.checkPage();
        vxm.general.readMessage(message._id).catch((error) => {
          return error;
        });
      }
    });

    if (this.contact) {
      this.getMessages().then(() => {
        this.$nextTick(() => {
          this.scrollToUnreadMess();
          if (this.contact?.lastSenderId === this.contact?.recipient?._id) {
            this.readMessages();
          }
        });
      });
    }
  }

  destroyed() {
    this.sockets.unsubscribe('NEW_MESSAGE');
  }

  @Watch('contact')
  onContactChange(val: MessagesGroupInterface | null, oldVal: MessagesGroupInterface | null) {
    if (val && val?._id !== oldVal?._id) {
      this.messages.items = [];
      this.getMessages().then(() => {
        this.$nextTick(() => {
          this.scrollToUnreadMess();
          if (this.contact?.lastSenderId === this.contact?.recipient?._id) {
            this.readMessages();
          }
        });
      });
    }
  }

  @Watch('searchQuery')
  onSearchQueryUpdate(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.debouncedSearch();
    }
  }

  isOutputMessage(message: MessageInterface): boolean {
    return message.senderId === vxm.user.data._id;
  }

  getOldMessages() {
    if (this.messages.count > this.params.page * this.params.take) {
      this.params.page++;
      this.getMessages();
    }
  }

  getMessages() {
    return new Promise<void>((resolve) => {
      if (this.contact && this.contact.notSeenMessages > this.params.take) {
        this.params.take = this.contact.notSeenMessages;
      }

      vxm.general
        .getMessages({id: this.contact?._id, params: this.params})
        .then((res) => {
          this.messages.count = res.data.count;
          this.messages.items.push(...res.data.items);
          this.chatDates = [];
          resolve();
        })
        .catch((error) => {
          return error;
        });
    });
  }

  scrollToUnreadMess() {
    const messages = [...this.messages.items].reverse();
    for (let i = 0; i < messages.length; i++) {
      if ((messages[i].recipientIds === vxm.user.data._id && !messages[i].read) || i === messages.length - 1) {
        (this.$refs.messagesElements as Element[])[i].scrollIntoView();
        break;
      }
    }
  }

  search() {
    this.params.page = 1;
    this.params.take = 20;
    this.params.keyword = this.searchQuery;
    this.messages.items = [];
    this.getMessages();
  }

  closeSearch() {
    this.showSearchInput = false;
    if (!this.params.keyword) {
      return;
    }
    this.params.keyword = '';
    this.params.page = 1;
    this.params.take = 20;
    this.messages.items = [];
    this.getMessages();
  }

  datesFilterChanged(dates: {start: string; end: string}) {
    this.datesFilter = dates;
    this.params.page = 1;
    this.params.take = 20;
    this.params.start = moment(dates.start).format();
    this.params.end = moment(dates.end)
      .add(1, 'days')
      .format();
    this.messages.items = [];
    this.getMessages();
  }

  readMessages() {
    vxm.general
      .readAllMessages({groupId: this.contact?._id, data: {toDate: moment().format()}})
      .then(() => {
        vxm.general.checkHasUnreadMessages().catch((error) => {
          return error;
        });
      })
      .catch((error) => {
        return error;
      });
    this.$emit('readMessages', 0);
  }

  sendMessage() {
    if (this.message) {
      const data = {
        content: this.message,
        messageGroupId: this.contact?._id,
      };
      vxm.general
        .createMessage(data)
        .then((res) => {
          this.messages.items.unshift(res.data);
          this.messages.count++;
          this.checkPage();
          this.$emit('newMessage', res.data);
          this.$nextTick(() => {
            (this.$refs.messagesElements as Element[])[this.messages.items.length - 1].scrollIntoView();
          });
        })
        .catch((error) => {
          return error;
        });
      this.message = '';
    }
  }

  sendMedia(e) {
    if (!e.target.files.length) {
      return;
    }

    const file: File = e.target.files[0];
    const type = file.type.split('/')[0];

    if (!(type === 'image' || type === 'video')) {
      this.$notify({
        group: 'foo',
        title: this.$t('error.invalid-file') as string,
        type: 'error',
        duration: 5000,
        text: this.$t('error.invalid-file-description') as string,
      });

      return;
    }

    if (
      (vxm.user.data.role === 'user' && file.size > FileSizeEnum.MAX_USER_CHAT_FILE) ||
      (vxm.user.data.role === 'performer' && file.size > FileSizeEnum.MAX_MODEL_CHAT_FILE)
    ) {
      const maxSize =
        vxm.user.data.role === 'user' ? FileSizeEnum.MAX_USER_CHAT_FILE : FileSizeEnum.MAX_MODEL_CHAT_FILE;
      this.$notify({
        group: 'foo',
        title: this.$t('error.max-size') as string,
        type: 'error',
        duration: 5000,
        text: this.$t('error.max-size-description', {n: this.sizeFormat(maxSize)}) as string,
      });
      return;
    }

    const tempId = moment().format();
    const message: MessageInterface = {
      __v: 0,
      _id: tempId,
      content: '',
      createdAt: tempId,
      messageGroupId: this.contact?._id as string,
      read: false,
      recipientIds: this.contact?.recipient?._id as string,
      senderId: vxm.user.data._id,
      type,
      updatedAt: tempId,
    };

    this.messages.items.unshift(message);
    this.messages.count++;
    this.checkPage();
    this.$nextTick(() => {
      (this.$refs.messagesElements as Element[])[this.messages.items.length - 1].scrollIntoView();
    });

    vxm.general
      .getPreSignedPostData({
        key: file.name,
        mediaType: file.type,
        type: `messages.${type}`,
      })
      .then((res) => {
        const data = new FormData();
        Object.keys(res.data.fields).forEach((key) => {
          data.append(key, res.data.fields[key]);
        });
        data.append('file', file);

        vxm.general
          .directUpload({
            url: res.data.url,
            data,
          })
          .then(() => {
            const mediaMessage = {
              content: res.data.fileToken,
              messageGroupId: this.contact?._id as string,
            };

            if (type === 'image') {
              vxm.general
                .createImageMessage(mediaMessage)
                .then((messageRes) => {
                  this.updateMediaMessage(tempId, messageRes.data);
                })
                .catch((error) => {
                  return error;
                });
            }

            if (type === 'video') {
              vxm.general
                .createVideoMessage(mediaMessage)
                .then((messageRes) => {
                  this.updateMediaMessage(tempId, messageRes.data);
                })
                .catch((error) => {
                  return error;
                });
            }
          })
          .catch((error) => {
            return error;
          });
      })
      .catch((error) => {
        return error;
      });
  }

  updateMediaMessage(tempId: string, message: MessageInterface) {
    this.$emit('newMessage', message);
    for (let i = 0; i < this.messages.items.length; i++) {
      if (tempId === this.messages.items[i]._id) {
        this.$set(this.messages.items, i, message);
        break;
      }
    }
  }

  checkPage() {
    if (this.params.page * this.params.take < this.messages.items.length) {
      this.params.page = 1;
      this.params.take = this.messages.items.length;
    }
  }

  isNewChatDate(message: MessageInterface): boolean {
    if (!this.chatDates.length) {
      this.chatDates.push(message.createdAt);
      return true;
    }

    if (this.chatDates.includes(message.createdAt)) {
      return true;
    }

    if (
      moment(this.chatDates[this.chatDates.length - 1])
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .add(1, 'days')
        .isBefore(moment(message.createdAt))
    ) {
      this.chatDates.push(message.createdAt);
      return true;
    }

    return false;
  }

  showSearchBlock() {
    this.showSearchInput = true;
    this.$nextTick(() => {
      (this.$refs.searchInput as HTMLInputElement).focus();
    });
  }

  selectEmoji(emoji) {
    this.message += emoji.data;
  }

  scrollToLast() {
    const messages = this.$refs.messagesElements as Element[];
    messages[messages.length - 1]?.scrollIntoView();
  }

  getAvatarLink(link: string | undefined): string {
    return link ? link : require('@/assets/images/icons/upload.svg');
  }

  insertVidsLink(vids: VideoInterface) {
    const link = `${window.location.origin}/movie/${vids._id}`;
    this.message += this.message ? ` ${link}` : link;
    this.showVidsLink = false;
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
