














import {Component, Mixins, Watch} from 'vue-property-decorator';
import {VideoInterface} from '@/types/videoInterface';
import {VideosSearchParamsInterface} from '@/types/VideosSearchParamsInterface';
import {vxm} from '@/store';
import DebounceMixin from '@/mixins/DebounceMixin';

@Component({})
export default class VidsPlusLinkPicker extends Mixins(DebounceMixin) {
  vids: VideoInterface[] = [];
  isRequestSend = false;
  totalVids = 0;
  params: VideosSearchParamsInterface = {
    page: 1,
    take: 10,
    performerId: vxm.user.data._id,
    isSaleVideo: true,
    sort: 'newest',
    performerRole: 'owner',
  };
  searchQuery = '';
  debouncedSearch = this.debounce(this.search, 700);

  mounted() {
    this.getVids();
    (this.$refs.scrollContainer as Element).addEventListener('scroll', this.handleScroll);
  }

  @Watch('searchQuery')
  onSearchQueryChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.debouncedSearch();
    }
  }

  handleScroll() {
    const element = this.$refs.scrollContainer as Element;
    const bottomOfWindow = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (bottomOfWindow && !this.isRequestSend && this.totalVids > this.vids.length) {
      this.getVids();
    }
  }

  getVids() {
    this.isRequestSend = true;
    vxm.model
      .getMyVideos(this.params)
      .then((res) => {
        this.totalVids = res.data.count;
        this.vids.push(...res.data.items);
        (this.params.page as number)++;
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
  }

  selectVideo(video: VideoInterface) {
    this.$emit('selectVideo', video);
  }

  search() {
    this.params.keyword = this.searchQuery;
    this.params.page = 1;
    this.vids = [];
    this.getVids();
  }
}
