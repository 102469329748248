


























































import {Component, Mixins, Vue, Watch} from 'vue-property-decorator';
import DropDown from '@/components/DropDown.vue';
import ChatMessages from '@/components/ChatMessages.vue';
import Modal from '@/components/Modal.vue';
import {vxm} from '@/store';
import {MessagesGroupInterface} from '@/types/messagesGroupInterface';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import {MessageInterface} from '@/types/messageInterface';
import lineClamp from 'vue-line-clamp';
import DebounceMixin from '@/mixins/DebounceMixin';
import Loader from '@/components/Loader.vue';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';
import BulkMessageModal from '@/components/modals/BulkMessageModal.vue';

Vue.use(lineClamp);

@Component({
  components: {BulkMessageModal, Modal, ChatMessages, DropDown, Loader},
})
export default class Messages extends Mixins(MomentDateMixin, DebounceMixin, DefaultAvatarMixin) {
  contacts: MessagesGroupInterface[] = [];
  isMobile = false;
  showChatModal = false;
  currentChat: MessagesGroupInterface | null = null;
  searchQuery = '';
  debouncedSearch = this.debounce(this.changeSearch, 700);
  params = {
    page: 1,
    take: 20,
    type: this.isPerformer ? 'subscriber' : 'model',
    sortRead: null,
    keyword: '',
  };
  sortOptions = [
    {
      value: null,
      name: 'Sort',
    },
    {
      value: 1,
      name: 'Read',
    },
    {
      value: -1,
      name: 'Not Seen',
    },
  ];
  isRequestSend = false;
  isLastContact = false;

  get isPerformer(): boolean {
    return vxm.user.role === 'performer';
  }

  get activeContacts() {
    return this.contacts.filter((contact) => {
      return !!contact.recipient;
    });
  }

  get hasUnreadSubscriberMessages(): boolean {
    return vxm.general.hasUnreadSubscriberMessages;
  }

  get hasUnreadModelMessages(): boolean {
    return vxm.general.hasUnreadModelMessages;
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  mounted() {
    if (this.$refs.contactsBlock) {
      (this.$refs.contactsBlock as Element).addEventListener('scroll', this.handleScroll);
    }

    if (this.$route.query.chatType === 'model') {
      this.params.type = 'model';
    }

    this.getContacts().then(() => {
      if (this.$route.query.chatId) {
        this.findAndMarkChat(this.$route.query.chatId as string);
      }
    });

    this.sockets.subscribe('NEW_MESSAGE', (message: MessageInterface) => {
      const chatType = message.senderRole === 'user' ? 'subscriber' : 'model';
      if (chatType === this.params.type) {
        for (let i = 0; i < this.contacts.length; i++) {
          if (this.contacts[i]._id === message.messageGroupId) {
            if (message.messageGroupId !== this.currentChat?._id) {
              this.contacts[i].notSeenMessages++;
              this.setUnreadMessages(chatType);
            }
            this.updateContact(this.contacts[i], message);
            this.moveContactToTop(i);
            break;
          }

          if (i === this.contacts.length - 1) {
            this.addNewContact(message.messageGroupId);
            this.setUnreadMessages(chatType);
          }
        }
      } else {
        this.setUnreadMessages(chatType);
      }
    });
  }

  destroyed() {
    window.removeEventListener('resize', this.onResize);
    this.sockets.unsubscribe('NEW_MESSAGE');
  }

  onResize() {
    this.isMobile = window.innerWidth <= 823;
  }

  @Watch('searchQuery')
  onSearchQueryChanged(val: string, oldVal: string) {
    if (val !== oldVal) {
      this.debouncedSearch();
    }
  }

  handleScroll() {
    const element = this.$refs.contactsBlock as Element;
    const bottomOfWindow = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (bottomOfWindow && !this.isRequestSend && !this.isLastContact) {
      this.getContacts();
    }
  }

  getContacts() {
    return new Promise<void>((resolve) => {
      this.isRequestSend = true;
      vxm.general
        .getMessagesGroups(this.params)
        .then((res) => {
          if (!res.data.length) {
            this.isLastContact = true;
            resolve();
            return;
          }

          this.contacts.push(...res.data);
          this.params.page++;
          resolve(res.data);
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    });
  }

  getFirstPage() {
    this.params.page = 1;
    this.contacts = [];
    this.isLastContact = false;
    return this.getContacts().then(() => {
      this.markSelectedContact();
    });
  }

  addNewContact(contactId) {
    const params = JSON.parse(JSON.stringify(this.params));
    params.id = contactId;
    params.page = 1;
    params.take = 1;
    return new Promise((resolve) => {
      vxm.general
        .getMessagesGroups(params)
        .then((res) => {
          this.contacts.unshift(...res.data);
          resolve(res.data);
        })
        .catch((error) => {
          return error;
        });
    });
  }

  markSelectedContact() {
    if (!this.currentChat) {
      return;
    }

    for (let i = 0; i < this.contacts.length; i++) {
      if (this.contacts[i]._id === this.currentChat._id) {
        this.$set(this.contacts, i, this.currentChat);
        break;
      }
    }
  }

  moveContactToTop(index: number) {
    this.contacts.unshift(...this.contacts.splice(index, 1));
  }

  changeSearch() {
    this.params.keyword = this.searchQuery;
    this.getFirstPage();
  }

  selectChat(contact: MessagesGroupInterface) {
    if (this.isMobile) {
      this.showChatModal = true;
    }
    this.currentChat = contact;
  }

  changeSort() {
    this.getFirstPage();
  }

  changeTab(newType: string) {
    if (newType === this.params.type) {
      return;
    }

    this.params.type = newType;
    this.getFirstPage();
  }

  updateCurrentChat(message: MessageInterface) {
    this.updateContact(this.currentChat as MessagesGroupInterface, message);

    for (let i = 0; i < this.contacts.length; i++) {
      if (this.contacts[i]._id === message.messageGroupId) {
        this.moveContactToTop(i);
        break;
      }
    }
  }

  updateContact(contact: MessagesGroupInterface, message: MessageInterface) {
    if (message.type === 'image' || message.type === 'video') {
      contact.lastContent = 'Shared media';
    } else {
      contact.lastContent = message.content;
    }
    contact.lastMessageCreatedAt = message.createdAt;
    contact.lastMessageId = message._id;
    contact.lastMessageRead = message.read;
    contact.lastSenderId = message.senderId;
  }

  findAndMarkChat(chatId: string) {
    for (let i = 1; i < this.contacts.length; i++) {
      if (chatId === this.contacts[i]._id) {
        this.moveContactToTop(i);
        this.currentChat = this.contacts[0];
        break;
      }

      if (i === this.contacts.length - 1) {
        this.addNewContact(chatId).then(() => {
          if (this.contacts.length) {
            this.currentChat = this.contacts[0];
          }
        });
      }
    }
  }

  refreshChats() {
    const chatId = this.currentChat?._id;
    this.currentChat = null;
    this.getFirstPage().then(() => {
      if (chatId) {
        this.findAndMarkChat(chatId);
      }
    });
  }

  readMessages(unreadMessages: number) {
    if (this.currentChat) {
      this.currentChat.notSeenMessages = unreadMessages;
    }
  }

  setUnreadMessages(chatType: string) {
    chatType === 'model' ? vxm.general.setUnreadModelMessages(true) : vxm.general.setUnreadSubscriberMessages(true);
  }
}
