










































import {Component, Mixins} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import {VideoInterface} from '@/types/videoInterface';
import {VEmojiPicker} from 'v-emoji-picker';
import VidsPlusLinkPicker from '@/components/VidsPlusLinkPicker.vue';
import {vxm} from '@/store';
import {FileSizeEnum} from '@/types/enums/FileSizeEnum';
import NumbersMixin from '@/mixins/NumbersMixin';
import Loader from '@/components/Loader.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import {AxiosResponse} from 'axios';
import {SubscribersCountInterface} from '@/types/SubscribersCountInterface';

@Component({
  components: {
    Modal,
    VEmojiPicker,
    VidsPlusLinkPicker,
    Loader,
    ProgressBar,
  },
})
export default class BulkMessageModal extends Mixins(NumbersMixin) {
  showPopup = false;
  showEmoji = false;
  showVidsLink = false;
  isRequestSend = false;
  message = '';
  subscribersType = 'all';
  subscribersList = [
    {
      value: 'all',
      title: 'All',
      count: 0,
    },
    {
      value: 'active',
      title: 'Active',
      count: 0,
    },
    {
      value: 'inactive',
      title: 'Expired',
      count: 0,
    },
  ];
  file: File | null = null;
  loadingProgress: number | null = null;

  mounted() {
    vxm.model
      .getSubscribersCount()
      .then((res: AxiosResponse<SubscribersCountInterface>) => {
        this.subscribersList.forEach((value) => {
          value.count = res.data[value.value];
        });
      })
      .catch((error) => {
        return error;
      });
  }

  send() {
    if ((this.message || this.file) && !this.isRequestSend) {
      this.isRequestSend = true;
      Promise.all([this.createTextMessage(), this.createMediaMessage()])
        .then(() => {
          this.message = '';
          this.file = null;
          this.loadingProgress = null;
          setTimeout(() => {
            this.$emit('refreshChats');
          }, 5000);
          this.showPopup = false;
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }

  createTextMessage() {
    if (!this.message) {
      return Promise.resolve();
    }
    return vxm.model.createBulkMessage({content: this.message, subscribersType: this.subscribersType});
  }

  createMediaMessage() {
    if (!this.file) {
      return Promise.resolve();
    }
    const type = this.file.type.split('/')[0];
    const data = {
      file: this.file,
      subscribersType: this.subscribersType,
    };
    const params = {
      data,
      uploadProgressCallback: (progressEvent) => {
        this.loadingProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      },
    };
    if (type === 'image') {
      return vxm.model.createBulkImageMessage(params);
    }
    if (type === 'video') {
      return vxm.model.createBulkVideoMessage(params);
    }
    return Promise.reject('Invalid file');
  }

  addMedia(e) {
    if (!e.target.files.length) {
      return;
    }

    const file: File = e.target.files[0];
    const type = file.type.split('/')[0];

    if (!(type === 'image' || type === 'video')) {
      this.$notify({
        group: 'foo',
        title: this.$t('error.invalid-file') as string,
        type: 'error',
        duration: 5000,
        text: this.$t('error.invalid-file-description') as string,
      });

      return;
    }

    const maxSize = FileSizeEnum.MAX_MODEL_CHAT_FILE;
    if (file.size > maxSize) {
      this.$notify({
        group: 'foo',
        title: this.$t('error.max-size') as string,
        type: 'error',
        duration: 5000,
        text: this.$t('error.max-size-description', {n: this.sizeFormat(maxSize)}) as string,
      });
      return;
    }

    this.file = file;
  }

  removeFile() {
    this.file = null;
    (this.$refs.fileInput as HTMLInputElement).value = '';
  }

  selectEmoji(emoji) {
    this.message += emoji.data;
  }

  insertVidsLink(vids: VideoInterface) {
    const link = `${window.location.origin}/movie/${vids._id}`;
    this.message += this.message ? ` ${link}` : link;
    this.showVidsLink = false;
  }
}
